<template>
    <div class="fixed-action-btn">
        <a class="btn-floating btn-large teal darken-1 modal-trigger" href="#soft-kitty">
            <i class="large material-icons">add</i>
        </a>
    </div>

    <div id="soft-kitty" class="modal" style="width: 75% !important">
        <div class="modal-content">
            <h6 class="center teal-text text-darken-1">Add Voter to Poll</h6>
            <div v-if="pollCategory === 'Education'">
                <form>
                    <div class="input-field">
                        <input id="regno" type="text" class="validate" v-model="eduRegNo" required autocomplete="off">
                        <label for="regno">Registration Number</label>
                    </div>

                    <section>
                        <div v-if="eduLoader" class="center">
                            <loader-component />
                        </div>
                        <div v-else>
                            <button class="btn teal darken-4 rounded full" @click.prevent="eduAdd">Add Voter</button>
                        </div>
                    </section>
                </form>
            </div>
            <div v-else>
                <form>
                    <div class="input-field">
                        <input id="tel" type="number" class="validate" v-model="phoneVal" required autocomplete="off">
                        <label for="tel">Phone Number</label>
                    </div>
                    <section>
                        <div v-if="phoneLoader" class="center">
                            <loader-component />
                        </div>
                        <div v-else>
                            <button class="btn teal darken-4 rounded full" @click.prevent="checkDefault">Add Voter</button>
                        </div>
                    </section>
                </form>
            </div>
        </div>
        <div class="modal-footer">
            <a class="modal-close waves-effect waves-green btn-flat red-text text-darken-4">Close</a>
        </div>
  </div>
</template>

<script>
import M from 'materialize-css'
import LoaderComponent from './LoaderComponent.vue';
import axios from 'axios';

export default {
    props: ['poll', 'category'],
    data(){
        return {
            authToken: localStorage.getItem('xWtyHBansFSGsOAMsdOAfQmcnKAs'),
            eduRegNo: null,
            eduLoader: false,
            phoneLoader: false,
            phoneVal: null,
            pollCategory: window.atob(window.atob(window.atob(window.atob(this.category)))),
        }
    },
    methods: {
        reloadParent(){
            this.$parent.reloadRegister();
        },
        checkDefault(){
            if (this.phoneVal === "" || this.phoneVal === null || this.phoneVal === 0) {
                alert('Empty Field - Phone Number');
            } else {
                this.phoneLoader = true;
                this.addDefaultVoter();
            }
        },
        async addDefaultVoter(){
            let data = new FormData();
            data.append('nvk', this.authToken);
            data.append('nvt', this.poll);
            data.append('nvp', this.phoneVal);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/nvd', data);
                let returnCondition = response.data.error
                let returnMessage = response.data.message 

                if (returnCondition === false) {
                    this.phoneLoader = false
                    M.toast({html: returnMessage});
                    this.closeModal();
                    this.reloadParent();
                } else {
                    this.phoneLoader = false
                    M.toast({html: returnMessage});
                }
            } catch (error) {
                this.phoneLoader = false
                M.toast({html: 'Network Error. Try Again'});
                //console.log(error); 
            }
        },
        closeModal(){
            var elem = document.getElementById("soft-kitty");
            var instance = M.Modal.getInstance(elem);
            instance.close();
        },
        eduAdd(){
            if (this.eduRegNo === "" || this.eduRegNo === null || this.eduRegNo === 0) {
                alert('Empty Field - Registration Number');
            } else {
                this.eduLoader = true;
                this.eduAction();
            }
        },
        async eduAction(){
            let data = new FormData();
            data.append('npk', this.authToken);
            data.append('npt', this.poll);
            data.append('npr', this.eduRegNo);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/nvp', data);
                let returnCondition = response.data.error
                let returnMessage = response.data.message 

                if (returnCondition === false) {
                    this.phoneLoader = false
                    M.toast({html: returnMessage});
                    this.eduLoader = false;
                    this.eduRegNo = "";
                    this.closeModal();
                    this.reloadParent();
                } else {
                    this.eduLoader = false;
                    M.toast({html: returnMessage});
                }
            } catch (error) {
                this.eduLoader = false;
                M.toast({html: 'Network Error. Try Again'});
                //console.log(error);
            }
        },
    },
    mounted(){
        M.AutoInit();
    },
    computed: {
        fromStoreAngel(){
            return this.$store.state.storeAngel;
        },
    },
    components:{ LoaderComponent, }
}
</script>

<style scoped>
    .rounded{
        border-radius: 7px;
    }

    .full{
        width: 100%
    }
</style>