<template>
    <section>
        <nav class="transparent">
            <div class="nav-wrapper">
                <form>
                    <div class="input-field">
                        <input @keyup="searchMonitor()" id="search" type="search" required v-model="searchParam" list="search_output" placeholder="Search Polls" autocomplete="off">
                        <label class="label-icon" for="search"><i class="material-icons">search</i></label>
                        <i class="material-icons">close</i>
                    </div>
                </form>
            </div>
        </nav>

        <div v-if="searchLoading" style="margin-top: 14px; margin-bottom: 14px;" class="center">
            <loader-component />
        </div>
        <div v-else>
            <div style="margin-top: 14px;">
                <div v-if="searchPolls.length">
                    <div v-for="poll in searchPolls" :key="poll">
                        <ul class="collection" style="border-radius: 7px; margin-top: 7px; margin-bottom: 7px;">
                            <li class="collection-item hoverable">
                                <router-link :to="{name: 'poll', params: {pageValue: poll.tz}}">
                                    <span class="teal-text text-darken-4">{{poll.tp}}</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-else>
                    <p class="center red-text text-darken-4">{{searchMessage}}</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import LoaderComponent from './LoaderComponent.vue';

export default {
  components: { LoaderComponent },
    data(){
        return{
            authToken: localStorage.getItem('xWtyHBansFSGsOAMsdOAfQmcnKAs'),
            searchPolls: [],
            searchParam: null,
            searchLoading: false,
            searchMessage: null,
        }
    },
    methods: {
        searchMonitor(){
            if (this.searchParam === "" || this.searchParam === null || this.searchParam === 0) {
                this.searchPolls = []
                this.searchMessage = null
            } else {
                this.searchLoading = true
                this.doTheThing();
            }
        },
        async doTheThing(){
            let data = new FormData()
            data.append('spk', this.authToken)
            data.append('spp', this.searchParam)

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/sp', data)
                let returnCondition = response.data.error
                let returnMessage = response.data.message

                if (returnCondition === false) {
                    this.searchLoading = false
                    this.searchMessage = null
                    this.searchPolls = response.data.thread
                } else {
                    this.searchLoading = false
                    this.searchMessage = returnMessage
                    this.searchPolls = []
                }
            } catch (error) {
                this.searchLoading = false
                this.searchMessage = 'Network Error. Try Again'
                this.searchPolls = []
                //console.log(error);
            }
        }
    },
}
</script>

<style>

</style>