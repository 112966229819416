<template>
    <navbar-secondary />
    <sidebar />
    <div class="container">
        <div v-if="aspirantsLoader" class="center">
            <loader-component />
        </div>
        <div v-else>
            <div v-if="aspirants.length">
                <blockquote>{{ poll }} / {{ category }} / Aspirants</blockquote>
                <div class="row">
                    <div class="col s12 m6 l4" v-for="aspirant in aspirants" :key="aspirant">
                        <div class="card rounded">
                            <div class="card-content center">
                                <img class="rounded-full" loading="lazy" :src=aspirant.asi alt="" height="50" width="50">
                                <br>
                                <span>{{ aspirant.asn }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <p class="center red-text text-darken-4">{{ aspirantsMessage }}</p>
            </div>

            <div v-if="allowCreation">
                <aspirant-creator-secondary :thisPoll="pageValue" :thisCategory="categoryID"/> 
            </div>
        </div>
    </div>
    <footer-primary />
</template>

<script>
import M from 'materialize-css'
import NavbarSecondary from '@/components/NavbarSecondary.vue'
import Sidebar from '@/components/Sidebar.vue'
import FooterPrimary from '@/components/FooterPrimary.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'
import AspirantCreatorSecondary from '@/components/AspirantCreatorSecondary.vue'
import axios from 'axios'

export default {
    props: ['pageValue', 'categoryID'],
    data(){
        return {
            authToken: localStorage.getItem('xWtyHBansFSGsOAMsdOAfQmcnKAs'),
            aspirantsLoader: true,
            aspirantsMessage: null,
            aspirants: [],
            poll: null,
            category: null,
            allowCreation: false,
        }
    },
    methods: {
        loadAspirants(){
            this.get_aspirants();
        },
        async get_aspirants(){
            let data = new FormData()
            data.append('cak', this.authToken)
            data.append('cat', this.pageValue)
            data.append('cap', this.categoryID)

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/ca', data)
                let returnCondition = response.data.error 
                let returnMessage = response.data.message 

                if (returnCondition === false) {
                    this.aspirantsLoader = false
                    this.aspirantsMessage = null
                    this.aspirants = response.data.thread
                    this.poll = response.data.tp
                    this.category = response.data.tc
                    this.allowCreation = response.data.abc
                } else {
                    this.aspirantsLoader = false
                    this.aspirantsMessage = returnMessage
                    this.aspirants = []
                    this.allowCreation = response.data.abc
                }
            } catch (error) {
                this.aspirantsLoader = false
                this.aspirantsMessage = 'Network Error. Try Again'
                this.aspirants = []
                //console.log(error);
            }
        },
    },
    mounted(){
        this.get_aspirants();
    },
    created(){
        document.title = this.$route.meta.title;
    },
    components: { NavbarSecondary, Sidebar, FooterPrimary, LoaderComponent, AspirantCreatorSecondary, },
}
</script>

<style scoped>
    .rounded{
        border-radius: 14px;
    }
    .rounded-full{
        border-radius: 49px;
    }
</style>