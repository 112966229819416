<template>
    <div class="fixed-action-btn">
        <a class="btn-floating btn-large teal darken-1 modal-trigger" href="#add-aspirant">
            <i class="large material-icons">add</i>
        </a>
    </div>

    <div class="modal" id="add-aspirant">
        <div class="modal-content">
            <h6 class="center teal-text text-darken-1">Add A New Apirant</h6>
            <br>
            <form>
                <div class="input-field">
                    <input id="asp_name" type="text" class="validate" autocomplete="off" required v-model="aspname">
                    <label for="asp_name" class="teal-text text-darken-1">Official Name</label>
                </div>
                <div class="input-field">
                    <input id="asp_disp" type="text" class="validate" autocomplete="off" required v-model="aspdisp">
                    <label for="asp_disp" class="teal-text text-darken-1">Ballot Name</label>
                </div>
                <div class="file-field input-field">
                    <div class="btn teal darken-1">
                        <span>Image</span>
                        <input type="file" id="file" accept='image/*' ref="file" >
                    </div>
                    <div class="file-path-wrapper">
                        <input class="file-path validate" type="text">
                    </div>
                </div>
                <br>
                <div>
                    <div class="center" v-if="asploader">
                        <loader-component />
                    </div>
                    <div v-else>
                        <button class="btn teal darken-1 white-text rounded full" @click.prevent="validateInputs">Add Aspirant</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <a class="modal-close waves-effect waves-green btn-flat red-text text-darken-4">Close</a>
        </div>
    </div>    
</template>

<script>
import M from 'materialize-css'
import axios from 'axios';
import LoaderComponent from './LoaderComponent.vue';

export default {
    props:['thisPoll'],
    data(){
        return{
            authToken: localStorage.getItem('xWtyHBansFSGsOAMsdOAfQmcnKAs'),
            aspname: null,
            aspdisp: null,
            aspimage: null,
            asploader: false,
            file: '',
        }
    },
    methods: {
        loadMain(){
            this.$parent.reloadAspirants();
        },
        validateInputs(){
            if (this.aspname === "" || this.aspname === null || this.aspname === 0) {
                alert("Empty Field - Apirant's Official Name")
            }else if(this.aspdisp === "" || this.aspdisp === null || this.aspdisp === 0){
                alert("Empty Field - Apirant's Ballot Name")
            }else {
                this.asploader = true;
                this.uploadData();
            }
        },
        async uploadData(){
            this.file = this.$refs.file.files[0];
            let data = new FormData();
            data.append('file', this.file);
            data.append('nk', this.authToken);
            data.append('nt', this.thisPoll);
            data.append('nc', this.aspname);
            data.append('nb', this.aspdisp);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/na', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                let returnCondition = response.data.error;
                let returnMessage = response.data.message;

                if (returnCondition === false) {
                    this.asploader = false;
                    M.toast({html: returnMessage});
                    this.closeModal();
                    this.loadMain();
                    this.aspname = '';
                    this.file = '';
                } else {
                    this.asploader = false;
                    M.toast({html: returnMessage});
                }
            } catch (error) {
                this.asploader = false
                M.toast({html: 'Network Error. Try Again'});
                //console.log(error);
            }
        },
        closeModal(){
            var elem = document.getElementById("add-aspirant");
            var instance = M.Modal.getInstance(elem);
            instance.close();
        },
    },
    mounted(){
        M.AutoInit();
    },
    components: { LoaderComponent, }
}
</script>

<style scoped>
    .rounded{
        border-radius: 14px;
    }

    .full{
        width: 100%;
    }
</style>