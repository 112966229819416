<template>
    <navbar-secondary />
    <sidebar />
    <div class="container-fluid">
        <div v-if="pollLoader" class="center">
            <loader-component />
        </div>
        <div v-else>
            <div v-if="pollData.length">
                <div class="row">
                    <div class="col s12 m12 l12" v-for="data in pollData" :key="data">
                        <div class="card rounded">
                            <div class="card-content">
                                <span class="center card-title spec-text">{{ data.pn }}</span>
                                <p class="center"><small>{{ data.pd }}</small></p>
                                <br>
                                <div class="divider"></div>
                                <br>
                                <div class="row center">
                                    <div class="col s4 m4 l4">
                                        <div class="chip"><small class="white-text">{{ data.pcy }}</small></div>
                                    </div>
                                    <div class="col s4 m4 l4">
                                        <div class="chip"><small class="white-text">{{ data.pv }}</small></div>
                                    </div>
                                    <div class="col s4 m4 l4">
                                        <div class="chip"><small class="white-text">{{ data.pt }}</small></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col s12 m6 l4">
                        <div class="card rounded">
                            <div class="card-content center">
                                <span class="spec-text">Voter Register</span>
                                <div v-if="registerLoader" class="center">
                                    <loader-secondary />
                                </div>
                                <div v-else>
                                    <router-link :to="{name: 'voterm', params: {pageValue: pageValue, page: 1}}">
                                        <div v-if="registerData === null">
                                            <p class="center red-text text-darken-4"><small>{{ registerMessage }}</small></p>
                                        </div>
                                        <div v-else>
                                            <p class="flow-text">{{ registerData }}</p>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col s12 m6 l4">
                        <div v-if="pollCType === 'Yes/No'">
                            <aspirant-primary />
                        </div>
                        <div v-else-if="pollCType === 'Default'">
                            <aspirant-secondary :poll="pageValue"/>
                        </div>
                        <div v-else-if="pollCType === 'Pools'">
                            <aspirant-tertiary :poll="pageValue"/>
                        </div>
                        <div v-else>
                            <div class="card rounded">
                                <div class="card-content center">
                                    <span class="spec-text">Aspirants</span>
                                    <p class="flow-text"><small>Error. Contact The System Administrator</small></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col s12 m6 l4">
                        <div v-if="pollCType === 'Pools'">
                            <div class="card rounded">
                                <router-link :to="{name: 'results-sec', params: {pageValue: pageValue}}">
                                    <div class="card-content center">
                                        <span class="spec-text">Results</span>
                                        <p><i class="material-icons small">north_east</i></p>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                        <div v-else>
                            <div class="card rounded">
                                <router-link :to="{name: 'results-primary', params: {pageValue: pageValue}}">
                                    <div class="card-content center">
                                        <span class="spec-text">Results</span>
                                        <p><i class="material-icons small">north_east</i></p>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                        
                    </div>
                
                </div>

                <div v-if="pollStatus === 1">
                    <poll-deactivate :poll_name="pageValue"/>
                </div>
                <div v-else>
                    <poll-activate :poll_name="pageValue"/>
                </div>
            </div>
            <div v-else>
                <p class="center red-text text-darken-4">{{ pollMessage }}</p>
            </div>
        </div>
    </div>
    <footer-primary />
</template>

<script>
import NavbarSecondary from '@/components/NavbarSecondary.vue'
import Sidebar from '@/components/Sidebar.vue'
import FooterPrimary from '@/components/FooterPrimary.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'
import axios from 'axios'
import LoaderSecondary from '@/components/LoaderSecondary.vue'
import AspirantPrimary from '@/components/AspirantPrimary.vue'
import AspirantSecondary from '@/components/AspirantSecondary.vue'
import AspirantTertiary from '@/components/AspirantTertiary.vue'
import M from 'materialize-css'
import PollActivate from '@/components/PollActivate.vue'
import PollDeactivate from '@/components/PollDeactivate.vue'
export default {
    props: {
        pageValue: {
            required: true,
            type: String,
        }
    },
    data(){
        return{
            authToken: localStorage.getItem('xWtyHBansFSGsOAMsdOAfQmcnKAs'),
            pollLoader: true,
            pollData: [],
            pollMessage: null,
            pollCType: null,
            registerLoader: true,
            registerMessage: null,
            registerData: null,
            pollStatus: null,
        }
    },
    beforeMount(){
        M.AutoInit();
    },
    mounted(){
        this.poll_details();
    },
    methods: {
        async poll_details(){
            let data = new FormData()
            data.append('pdk', this.authToken)
            data.append('pdx', this.pageValue)

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/ps', data)
                let returnCondition = response.data.error
                let returnMessage = response.data.message 

                if (returnCondition === false) {
                    this.pollLoader = false
                    this.pollMessage = null
                    this.pollData = response.data.thread
                    this.pollCType = response.data.thread[0].pct
                    this.pollStatus = response.data.a
                    this.voter_register();
                } else {
                    this.pollLoader = false
                    this.pollData = []
                    this.pollMessage = returnMessage
                }
            } catch (error) {
                this.pollLoader = false
                this.pollData = []
                this.pollMessage = 'Network Error. Try Again'
                //console.log(error);
            }
        },
        async voter_register(){
            let data = new FormData();
            data.append('vrk', this.authToken)
            data.append('vrt', this.pageValue)

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/pvc', data)
                let returnCondition = response.data.error
                let returnMessage = response.data.message

                if (returnCondition === false) {
                    this.registerLoader = false
                    this.registerMessage = null
                    this.registerData = response.data.gti
                } else {
                    this.registerLoader = false
                    this.registerMessage = returnMessage
                    this.registerData = null
                }
            } catch (error) {
                this.registerLoader = false
                this.registerData = null
                this.registerMessage = 'Network Error. Try Again'
                //console.log(error);
            }
        },
    },
    created(){
        document.title = this.$route.meta.title;
    },
    components: { NavbarSecondary, Sidebar, FooterPrimary, LoaderComponent, LoaderSecondary, AspirantPrimary, AspirantSecondary, AspirantTertiary, PollActivate, PollDeactivate },
}
</script>

<style scoped>
    .rounded{
        border-radius: 7px;
    }
</style>