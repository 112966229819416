<template>
    <div class="row">
        <div class="col s12 m2 l2"></div>
        <div class="col s12 m8 l8">
            <div class="card rounded">
                <div class="card-content center">
                    <img src="@/assets/rs_setup.svg" style="width: 152px; height: 152px;"/>
                    <form>
                        <div class="input-field">
                            <input id="suname" type="text" class="validate" required autocomplete="off" v-model="stp_uname">
                            <label for="suname" class="teal-text text-darken-1">Username</label>
                        </div>
                        <div class="input-field">
                            <input id="spass" type="password" class="validate" required autocomplete="off" v-model="stp_pass">
                            <label for="spass" class="teal-text text-darken-1">Password</label>
                        </div>
                        <div class="input-field">
                            <input id="scpass" type="password" class="validate" required autocomplete="off" v-model="stp_cpass">
                            <label for="scpass" class="teal-text text-darken-1">Confirm Password</label>
                        </div>
                        <br>
                        <div @click.prevent="togglePassword()" class="right" style="margin-bottom:0px; margin-top: -21px; cursor: pointer; text-decoration: none;">
                            <div v-if="password_status" style="cursor: pointer;">
                                <p id="l_toggle">Hide Password</p>
                            </div>
                            <div v-else style="cursor: pointer;">
                                <p id="l_toggle">Show Password</p>
                            </div>
                        </div>
                        <br>
                        <div>
                            <div class="center" v-if="stp_progress">
                                <loader-component />
                            </div>
                            <div v-else>
                                <button class="btn teal darken-4 white-text rounded full" @click.prevent="bouncer">Finish Setup</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col s12 m2 l2"></div>
    </div>
</template>

<script>
import LoaderComponent from './LoaderComponent.vue';
import axios from 'axios';
import M from 'materialize-css';
import router from '@/router';

export default {
    props:{
        targetToken: {
            type: String,
            required: true,
        }
    },
    data(){
        return{
            stp_uname: null,
            stp_pass: null,
            stp_cpass: null,
            stp_progress: false,
            password_status: false,
        }
    },
    methods: {
        bouncer(){
            if (this.stp_uname === null || this.stp_uname === "" || this.stp_uname === 0) {
                alert('Empty Field - Username')
            }else if (this.stp_pass === null || this.stp_pass === "" || this.stp_pass === 0) {
                alert('Empty Field - Password')
            }else if (this.stp_cpass === null || this.stp_cpass === "" || this.stp_cpass === 0) {
                alert('Empty Field - Confirm Password')
            } else {
                this.stp_progress = true
                this.doSetup();
            }
        },
        async doSetup(){
            let data = new FormData();
            data.append('stz', this.targetToken);
            data.append('stu', this.stp_uname);
            data.append('stp', this.stp_pass);
            data.append('stc', this.stp_cpass);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/stf', data)
                let returnCondition = response.data.error
                let returnMessage = response.data.message 

                if (returnCondition === false) {
                    this.stp_progress = false
                    M.toast({html: returnMessage});
                    this.redirectHome();
                } else {
                    this.stp_progress = false
                    M.toast({html: returnMessage});
                }
            } catch (error) {
                this.stp_progress = false
                M.toast({html: 'Network Error. Try Again'});
                //console.log(error);
            }
        },
        redirectHome(){
            router.push({name: 'home'});
        },
        togglePassword(){
            var x = document.getElementById("spass");
            var y = document.getElementById("scpass");
            var text = document.getElementById("l_toggle")
            if (x.type === "password") {
                this.password_status = true;
                text.innerHTML = "Hide Password";
                x.type = "text";
                y.type = "text";
            } else {
                this.password_status = false;
                text.innerHTML = "Show Password";
                x.type = "password";
                y.type = "password";
            }
        },
    },
    components: { LoaderComponent, },
}
</script>

<style scoped>
    .rounded{
        border-radius: 14px;
    }

    .full{
        width: 100%
    }
</style>