<template>
    <navbar-secondary />
    <sidebar />
    <div class="container">
        <div v-if="resultLoader" class="center">
            <loader-component />
        </div>
        <div v-else>
            <div v-if="results.length">
                <blockquote><span>{{ resultsPoll }} Poll</span> / <span class="teal-text text-darken-1">{{ resultTotal }}</span> Votes Cast</blockquote>
                <table class="highlight striped">
                    <thead class="teal-text text-darken-1">
                        <tr>
                            <td>Aspirant</td>
                            <td>Votes</td>
                            <td>Percentage</td>
                            <td>Visual</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="result in results" :key="result">
                            <td>{{ result.c }}</td>
                            <td>{{ result.v }}</td>
                            <td>{{ (((+result.v) / resultTotal) * 100).toFixed(2) }} <small>%</small></td>
                            <td>
                                <div class="progress">
                                    <div v-if="(((+result.v) / resultTotal) * 100).toFixed(2) < 25">
                                        <div class="determinate red darken-4" :style="{width: ((((+result.v) / resultTotal) * 100).toFixed(2)+'%')}"></div>
                                    </div>
                                    <div v-else-if="(((+result.v) / resultTotal) * 100).toFixed(2) >= 25 && (((+result.v) / resultTotal) * 100).toFixed(2) < 50">
                                        <div class="determinate yellow lighten-1" :style="{width: ((((+result.v) / resultTotal) * 100).toFixed(2)+'%')}"></div>
                                    </div>
                                    <div v-else-if="(((+result.v) / resultTotal) * 100).toFixed(2) >= 50 && (((+result.v) / resultTotal) * 100).toFixed(2) < 75">
                                        <div class="determinate blue darken-1" :style="{width: ((((+result.v) / resultTotal) * 100).toFixed(2)+'%')}"></div>
                                    </div>
                                    <div v-else>
                                        <div class="determinate teal darken-1" :style="{width: ((((+result.v) / resultTotal) * 100).toFixed(2)+'%')}"></div>
                                    </div>
                                    
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else>
                <p class="center red-text text-darken-">{{ resultMessage }}</p>
            </div>
        </div>
    </div>
    <footer-primary />
</template>

<script>
import NavbarSecondary from '@/components/NavbarSecondary.vue'
import Sidebar from '@/components/Sidebar.vue'
import FooterPrimary from '@/components/FooterPrimary.vue'
import axios from 'axios'
import LoaderComponent from '@/components/LoaderComponent.vue'
export default {
    props: ['pageValue'],
    data(){
        return {
            authToken: localStorage.getItem('xWtyHBansFSGsOAMsdOAfQmcnKAs'),
            resultLoader: true,
            resultMessage: null,
            results: [],
            resultTotal: null,
            resultsPoll: null,
        }
    },
    methods: {
        async fetchMeWillWheaton(){
            let data = new FormData()
            data.append('rpk', this.authToken)
            data.append('rpt', this.pageValue)

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/yin', data)
                let returnCondition = response.data.error
                let returnMessage = response.data.message 

                if (returnCondition === false) {
                    this.resultLoader = false
                    this.resultMessage = null
                    this.results = response.data.r
                    this.resultTotal = response.data.z
                    this.resultsPoll = response.data.p
                } else {
                    this.resultLoader = false
                    this.results = []
                    this.resultMessage = returnMessage
                }
            } catch (error) {
                this.resultLoader = false
                this.results = []
                this.resultMessage = 'Network Error. Try Again'
                //console.log(error);
            }
        },
    },
    mounted(){
        this.fetchMeWillWheaton();
    },
    created(){
        document.title = this.$route.meta.title;
    },
    components: { NavbarSecondary, Sidebar, FooterPrimary, LoaderComponent },
}
</script>

<style scoped>

</style>