<template>
    <navbar-secondary />
    <sidebar />
    <div class="container">
        <div v-if="resultLoader" class="center">
            <loader-component />
        </div>
        <div v-else>
            <div v-if="results.length">
                <blockquote>{{ resultsPoll }} / {{ resultsCat }} / <span class="teal-text text-darken-1">{{ resultsTotal }}</span> Votes Cast</blockquote>
                <table class="highlight striped">
                    <thead class="teal-text text-darken-1">
                        <tr>
                            <td>Aspirant</td>
                            <td>Votes</td>
                            <td>Percentage</td>
                            <!-- <td>Visual</td> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="result in results" :key="result">
                            <td>{{ result.c }}</td>
                            <td>{{ result.v }}</td>
                            <td>{{ (((+result.v) / resultsTotal) * 100).toFixed(2) }} <small>%</small></td>
                            <!-- <td>
                                <div class="progress">
                                    <div v-if="(((+result.v) / resultsTotal) * 100).toFixed(2) < 25">
                                        <div class="determinate red darken-4" :style="{width: ((((+result.v) / resultsTotal) * 100).toFixed(2)+'%')}"></div>
                                    </div>
                                    <div v-else-if="(((+result.v) / resultsTotal) * 100).toFixed(2) >= 25 && (((+result.v) / resultsTotal) * 100).toFixed(2) < 50">
                                        <div class="determinate yellow lighten-1" :style="{width: ((((+result.v) / resultsTotal) * 100).toFixed(2)+'%')}"></div>
                                    </div>
                                    <div v-else-if="(((+result.v) / resultsTotal) * 100).toFixed(2) >= 50 && (((+result.v) / resultsTotal) * 100).toFixed(2) < 75">
                                        <div class="determinate blue darken-1" :style="{width: ((((+result.v) / resultsTotal) * 100).toFixed(2)+'%')}"></div>
                                    </div>
                                    <div v-else>
                                        <div class="determinate teal darken-1" :style="{width: ((((+result.v) / resultsTotal) * 100).toFixed(2)+'%')}"></div>
                                    </div>
                                    
                                </div>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else>
                <p class="center red-text text-darken-4">{{ resultMessage }}</p>
            </div>
        </div>
    </div>
    <footer-primary />
</template>

<script>
import NavbarSecondary from '@/components/NavbarSecondary.vue'
import Sidebar from '@/components/Sidebar.vue';
import FooterPrimary from '@/components/FooterPrimary.vue';
import LoaderComponent from '@/components/LoaderComponent.vue';
import axios from 'axios';
export default {
    props: {
        pageValue: {
            type: String,
            required: true,
        },
        categoryID: {
            type: String,
            required: true,
        }
    },
    data(){
        return {
            authToken: localStorage.getItem('xWtyHBansFSGsOAMsdOAfQmcnKAs'),
            resultLoader: true,
            resultMessage: null,
            results: [],
            resultsPoll: null,
            resultsCat: null,
            resultsTotal: null
        }
    },
    methods:{
        async softKitty(){
            let data = new FormData();
            data.append('rck', this.authToken);
            data.append('rct', this.pageValue);
            data.append('rcd', this.categoryID);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/yin-yang', data)
                let returnCondition = response.data.error
                let returnMessage = response.data.message 

                if (returnCondition === false) {
                    this.resultLoader = false
                    this.resultMessage = null
                    this.results = response.data.r
                    this.resultsTotal = response.data.z
                    this.resultsPoll = response.data.p
                    this.resultsCat = response.data.c
                } else {
                    this.resultLoader = false
                    this.results = []
                    this.resultMessage = returnMessage
                }
            } catch (error) {
                this.resultLoader = false
                this.results = []
                this.resultMessage = 'Network Error. Try Again'
                //console.log(error);
            }
        },
    },
    mounted(){
        this.softKitty();
    },
    created(){
        document.title = this.$route.meta.title;
    },
    components: { NavbarSecondary, Sidebar, FooterPrimary, LoaderComponent },
}
</script>

<style>

</style>