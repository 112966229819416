<template>
    <header>
        <nav class="nav-wrapper transparent z-depth-0">
            <div class="container">
                <router-link :to="{name: 'dash', params: {pageValue: 1}}" class="brand-logo left red-text"><img src="@/assets/logo.png" alt="iVoting" class="img-dimen"></router-link>
                <a class="sidenav-trigger right show-on-medium-and-up show-on-small" data-target="mobile-links" style="cursor: pointer;">
                    <i class="material-icons teal-text text-darken-1 icon-dimen">account_circle</i>
                </a>
            </div>
        </nav>
    </header>
</template>

<script>
export default {

}
</script>

<style scoped>
    .img-dimen {
        height: 42px; 
        width: 42px; 
        margin: 10px;
    }

    .icon-dimen {
        font-size: 35px;
    }
</style>