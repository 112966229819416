import { createStore } from 'vuex'
import routerLogout from '@/functions/routerLogout'
import axios from 'axios';
import M from "materialize-css";

//const authToken = localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs");

export default createStore({
  state: {
    storeIdentifier: null,
    storeName: null,
    storeUname: null,
  },
  getters: {
  },
  mutations: {
    SET_STORE_IDENTIFIER(state, identifier){
      state.storeIdentifier = identifier
    },
    SET_STORE_NAME(state, name){
      state.storeName = name
    },
    SET_STORE_UNAME(state, uname){
      state.storeUname = uname
    },
  },
  actions: {
    async getUD(context){
      let data = new FormData();
      data.append("yHBtxSQmkLIfVByREoxZCV", localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs"));
      
      try {
        const response = await axios.post("https://a.ivoting.co.ke/AdjnINBsmPANd/yHBtxSQmkLIfVByREaxZCV", data)
        let returnCondition = response.data.error;
        let returnMessage = response.data.message;

        if (returnCondition == false) {
          let active_status = response.data.f;
          const loggedInAll = window.atob(window.atob(response.data.yg));
          const linArr = loggedInAll.split("&&&");
          //console.log(linArr[0] + linArr[1] + linArr[2]);
          
          if (active_status === false ) {
            // console.log('not active');
            routerLogout();
            M.toast({ html: 'Permissions Revoked By Administrator'});
          }else {
            //console.log('active'+ active_status);
            context.commit('SET_STORE_IDENTIFIER', linArr[0]);
            context.commit('SET_STORE_NAME', linArr[1]);
            context.commit('SET_STORE_UNAME', linArr[2]);
          }
        }else{
          routerLogout();
          M.toast({ html: returnMessage });
        }
      } catch (error) {
        // console.log(error);
        routerLogout();
        M.toast({ html: 'Network Error. Try Again.' });
      }
    },
  },
  modules: {
  }
})
