<template>
    <div class="card rounded">
        <div class="card-content center">
            <a href="#aspirant-view" class="modal-trigger" style="text-decoration: none;">
                <span class="spec-text">Choices</span>
                <p class="flow-text">2</p>
            </a>
        </div>
    </div>
    
    <section>
        <div id="aspirant-view" class="modal">
            <div class="modal-content">
                <h5 class="center teal-text text-darken-4">Choices</h5>
                <div class="row center">
                    <div class="col s6 m6 l6">
                        <img src="https://admin.ivoting.co.ke/default_images/Yes.png" alt="iVoting Yes" height="70" width="70">
                        <p>Yes</p>
                    </div>
                    <div class="col s6 m6 l6">
                        <img src="https://admin.ivoting.co.ke/default_images/No.png" alt="iVoting No" height="70" width="70">
                        <p>No</p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <a class="modal-close waves-effect waves-red btn-flat red-text text-darken-4">Close</a>
            </div>
        </div>
    </section>
</template>

<script>
import M from 'materialize-css'
export default {
    mounted(){
        M.AutoInit();
    }
}
</script>

<style>

</style>