<template>
    <section>
        <div class="fixed-action-btn">
            <a class="btn-floating btn-large teal darken-1 modal-trigger" href="#add-category">
                <i class="large material-icons">add</i>
            </a>
        </div>

        <div class="modal" id="add-category">
            <div class="modal-content">
                <h6 class="center teal-text text-darken-1">Add A New Category to Poll</h6>
                <br>
                <form>
                    <div class="input-field">
                        <input id="cat_name" type="text" class="validate" autocomplete="off" required v-model="cname">
                        <label for="cat_name" class="teal-text text-darken-4">Category Name</label>
                    </div>
                    <br>
                    <div>
                        <div class="center" v-if="modalLoader">
                            <loader-component />
                        </div>
                        <div v-else>
                            <button class="btn teal darken-4 white-text rounded full" @click.prevent="validateInputs">Add Category</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <a class="modal-close waves-effect waves-green btn-flat red-text text-darken-4">Close</a>
            </div>
        </div>
    </section>
</template>

<script>
import M from 'materialize-css'
import axios from 'axios';
import LoaderComponent from './LoaderComponent.vue';
export default {
    props:{
        poll_name: {
            type: String,
            required: true,
        }
    },
    data(){
        return{
            authToken: localStorage.getItem('xWtyHBansFSGsOAMsdOAfQmcnKAs'),
            cname: null,
            modalLoader: false,
        }
    },
    methods: {
        validateInputs(){
            if (this.cname === "" || this.cname === null || this.cname === 0) {
                alert('Empty Field - Category');
            }else{
                this.modalLoader = true;
                this.createCategory();
            }
        },
        async createCategory(){
            let data = new FormData();
            data.append('ck', this.authToken);
            data.append('ct', this.poll_name);
            data.append('cc', this.cname);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/nc', data);
                let returnCondition = response.data.error
                let returnMessage = response.data.message 

                if (returnCondition === false) {
                    this.modalLoader = false;
                    M.toast({html: returnMessage});
                    this.closeModal();
                    this.$parent.get_categories();
                } else {
                    this.modalLoader = false;
                    M.toast({html: returnMessage});
                }
            } catch (error) {
                this.modalLoader = false;
                M.toast({html: 'Network Error. Try Again'});
                //console.log(error);
            }
        },
        closeModal(){
            var elem = document.getElementById("add-category");
            var instance = M.Modal.getInstance(elem);
            instance.close();
        },
    },
    mounted(){
        M.AutoInit();
    },
    components: { LoaderComponent, }
}
</script>

<style scoped>
    .rounded{
        border-radius: 14px;
    }

    .full{
        width: 100%;
    }
</style>