import router from '../router/index.js'
import axios from 'axios';
import M from 'materialize-css'
import obliviate from './obliviate.js';

async function routerLogout() {
    //console.log('started');
    const userID = localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs");
    const sessID = localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs");

    if (userID === null || sessID === null) {
        obliviate();
    }else if (userID === "" || sessID === "") {
        obliviate();
    }else if (userID === 0 || sessID === 0) {
        obliviate();
    } else {
        //obliviate();
        let data = new FormData();
        data.append("lp", userID);
        data.append("ls", sessID);

        try {
            const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/so', data);
            let returnCondition = response.data.error;
            let returnMessage = response.data.message;

            if (returnCondition === false) {
                obliviate();
                //console.log('lout s');
            } else {
                M.toast({html: returnMessage});
                obliviate();
                //console.log('lout f');
            }
        } catch (error) {
            M.toast({html: 'Network Error. Try Again'});
            obliviate();
            //console.log(error);
        }
    }
}

export default routerLogout