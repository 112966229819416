import router from '../router/index.js'

function obliviate() {
    localStorage.removeItem("xWtyHBansFSGsOAMsdOAfQmcnKAs");
    sessionStorage.removeItem("xWtyHBansFSGsOAMsdOAfQmcnKAs");

    localStorage.removeItem("xWtyHBansFSGsOAMsdOAfQmcnKAs");
    sessionStorage.removeItem("xWtyHBansFSGsOAMsdOAfQmcnKAs");

    sessionStorage.clear();
    router.push({name: 'home'});
}   

export default obliviate