<template>
    <navbar-secondary />
    <sidebar />
    <div class="container">
        <div v-if="aspirantLoader" class="center">
            <loader-component />
        </div>
        <div v-else>
            <div v-if="poll != null">
                <blockquote>{{ poll }} / Aspirants</blockquote>
            </div>
            <div v-if="aspirants.length">
                <div class="row">
                    <div class="col s12 m6 l4" v-for="aspirant in aspirants" :key="aspirant">
                        <div class="card rounded">
                            <div class="card-content center">
                                <img class="rounded-full" loading="lazy" :src=aspirant.ai alt="" height="50" width="50">
                                <br>
                                <span>{{ aspirant.an }}</span>
                                <br>
                                <section>
                                    <div v-if="aspirant.ab === null"></div>
                                    <div v-else>
                                        <small>"{{ aspirant.ab }}"</small>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <p class="center red-text text-darken-4">{{ aspirantMessage }}</p>
            </div>

            <div v-if="allowCreation">
                <aspirant-creator-primary :thisPoll="pageValue"/>
            </div>
        </div>
    </div>
    <footer-primary />
</template>

<script>
import NavbarSecondary from '@/components/NavbarSecondary.vue'
import Sidebar from '@/components/Sidebar.vue'
import FooterPrimary from '@/components/FooterPrimary.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'
import axios from 'axios'
import AspirantCreatorPrimary from '@/components/AspirantCreatorPrimary.vue'

export default {
    props: ['pageValue'],
    data(){
        return{
            authToken: localStorage.getItem('xWtyHBansFSGsOAMsdOAfQmcnKAs'),
            aspirantLoader: true,
            aspirantMessage: null,
            aspirants: [],
            poll: null,
            allowCreation: false,
        }
    },
    methods: {
        reloadAspirants(){
            this.get_default_aspirants();
        },
        async get_default_aspirants(){
            let data = new FormData()
            data.append('amk', this.authToken)
            data.append('amt', this.pageValue)

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/am', data)
                let returnCondition = response.data.error 
                let returnMessage = response.data.message 

                if (returnCondition === false) {
                    this.aspirantLoader = false
                    this.aspirantMessage = null
                    this.aspirants = response.data.thread
                    this.poll = response.data.tp
                    this.allowCreation = response.data.a
                } else {
                    this.aspirantLoader = false
                    this.aspirantMessage = returnMessage
                    this.aspirants = [],
                    this.poll = response.data.tp
                    this.allowCreation = response.data.a
                }
            } catch (error) {
                this.aspirantLoader = false
                this.aspirantMessage = 'Network Error. Try Again'
                this.aspirants = []
                //console.log(error);
            }
        },
    },
    mounted(){
        this.get_default_aspirants();
    },
    created(){
        document.title = this.$route.meta.title;
    },
    components: { NavbarSecondary, Sidebar, FooterPrimary, LoaderComponent, AspirantCreatorPrimary, },
}
</script>

<style scoped>         
    .rounded{
        border-radius: 14px;
    }
    .rounded-full{
        border-radius: 49px;
    }
</style>