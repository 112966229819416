<template>
    <navbar-secondary />
    <sidebar />
    <div class="container">
        <div class="row">
            <div class="col s12 m2 l2"></div>
            <div class="col s12 m8 l8">
                <div class="card rounded-lg">
                    <div class="card-content center">
                        <i class="material-icons red-text text-darken-4 large">account_circle</i>
                        <p class="center red-text text-darken-4">{{ selectedVoter }}</p>
                        <br>
                        <form action="">
                            <div v-if="delegateProgress" class="center">
                                <loader-component />
                            </div>
                            <div v-else>
                                <button class="btn red darken-4 white-text rounded-lg full" @click.prevent="bouncerCheck">Basic</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer-primary />
</template>

<script>
import NavbarSecondary from '@/components/NavbarSecondary.vue'
import Sidebar from '@/components/Sidebar.vue'
import FooterPrimary from '@/components/FooterPrimary.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'
import router from '@/router'
import axios from 'axios'

export default {
    props: {
        pageValue: {
            type: String,
            required: true,
        },
        selectedVoter: {
            type: String,
            required: true,    
        },
    },
    data(){
        return {
            authToken: localStorage.getItem('xWtyHBansFSGsOAMsdOAfQmcnKAs'),
            delegateProgress: false,
        }
    },
    methods: {
        bouncerCheck(){
            if (this.pageValue === null || this.pageValue === "" || this.pageValue === 0) {
                alert('ErrCode 008');
            }else if (this.selectedVoter === null || this.selectedVoter === "null" || this.selectedVoter === 0) {
                alert('ErrCode 009');
            } else {
                this.delegateProgress = true;
                this.convertToBasic();
            }
        },
        async convertToBasic(){
            let data = new FormData();
            data.append('vtdk', this.authToken);
            data.append('vtdt', this.pageValue);
            data.append('vtdp', this.selectedVoter);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/vcb', data);
                let returnCondition = response.data.error
                let returnMessage = response.data.message

                if (returnCondition === false) {
                    this.delegateProgress = false
                    M.toast({html: returnMessage });
                    this.redirectToVoterRegister();
                } else {
                    this.delegateProgress = false
                    M.toast({html: returnMessage });
                }
            } catch (error) {
                this.delegateProgress = false
                M.toast({html: 'Network Error. Try Again'});
                //console.log(error);
            }
        },
        redirectToVoterRegister(){
            router.push({name: 'voterm', params: {pageValue: this.pageValue, page: 1}});
        }
    },
    created(){
        document.title = this.$route.meta.title;
    },
    components: { NavbarSecondary, Sidebar, FooterPrimary, LoaderComponent,  },
}
</script>

<style scoped>
    .rounded-sm{
        border-radius: 7px;
    }

    .rounded-lg{
        border-radius: 14px;
    }

    .full{
        width: 100%;
    }
</style>