<template>
    <div class="row">
        <div class="col s12 m2 l2"></div>
        <div class="col s12 m8 l8">
            <div class="card rounded">
                <div class="card-content center">
                    <img src="@/assets/rs_recovery.svg" alt=""  style="width: 152px; height: 152px;">
                    <form action="">
                        <div class="input-field">
                            <input id="rec_pass" type="password" class="validate" required autocomplete="off" v-model="recovery_password">
                            <label for="rec_pass" class="teal-text text-darken-1">Password</label>
                        </div>
                        <div class="input-field">
                            <input id="rec_cpass" type="password" class="validate" required autocomplete="off" v-model="recovery_confirm">
                            <label for="rec_cpass" class="teal-text text-darken-1">Confirm Password</label>
                        </div>
                        <br>
                        <div @click.prevent="togglePassword()" class="right" style="margin-bottom:0px; margin-top: -21px; cursor: pointer; text-decoration: none;">
                            <div v-if="password_status" style="cursor: pointer;">
                                <p id="l_toggle">Hide Password</p>
                            </div>
                            <div v-else style="cursor: pointer;">
                                <p id="l_toggle">Show Password</p>
                            </div>
                        </div>
                        <br>
                        <div>
                            <div class="center" v-if="recovery_progress">
                                <loader-component />
                            </div>
                            <div v-else>
                                <button class="btn teal darken-4 white-text rounded full" @click.prevent="bouncer">Recover Password</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col s12 m2 l2"></div>
    </div>
</template>

<script>
import LoaderComponent from '@/components/LoaderComponent.vue'
import axios from 'axios'
import M from 'materialize-css'
import router from '@/router'

export default {
    props: {
        validToken: {
            required: true,
            type: String,
        }
    },
    data(){
        return{
            recovery_password: null,
            recovery_confirm: null,
            recovery_loader: false,
            password_status: false,
            recovery_progress: false,
        }
    },
    methods: {
        bouncer(){
            if (this.recovery_password === null || this.recovery_password === "" || this.recovery_password === 0) {
                alert('Empty Field - Password')
            } else if (this.recovery_confirm === null || this.recovery_confirm === "" || this.recovery_confirm === 0) {
                alert('Empty Field - Confirm Password')
            } else {
                this.recovery_progress = true
                this.changePassword();
            }
        },
        async changePassword(){
            let data = new FormData();
            data.append('rccp', this.recovery_password);
            data.append('rccc', this.recovery_confirm);
            data.append('rccx', this.validToken);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/rcf', data)
                let returnCondition = response.data.error
                let returnMessage = response.data.message 

                if (returnCondition === false) {
                    this.recovery_progress = false
                    M.toast({html: returnMessage});
                    this.redirectHome();
                } else {
                    this.recovery_progress = false
                    M.toast({html: returnMessage});
                }
            } catch (error) {
                this.recovery_progress = false
                M.toast({html: 'Network Error. Try Again'});
                //console.log(error);
            }
        },
        redirectHome(){
            router.push({name: 'home'});
        },
        togglePassword(){
            var x = document.getElementById("rec_pass");
            var y = document.getElementById("rec_cpass");
            var text = document.getElementById("l_toggle")
            if (x.type === "password") {
                this.password_status = true;
                text.innerHTML = "Hide Password";
                x.type = "text";
                y.type = "text";
            } else {
                this.password_status = false;
                text.innerHTML = "Show Password";
                x.type = "password";
                y.type = "password";
            }
        },
    },  
    created(){
        document.title = this.$route.meta.title;
    },
    components: { LoaderComponent },
}
</script>

<style scoped>
    .rounded{
        border-radius: 14px;
    }

    .full{
        width: 100%
    }
</style>