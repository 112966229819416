<template>
    <h4 class="center teal-text text-darken-4">Error 404</h4>
    <p class="center teal-text text-darken-4">Page Not Found</p>
    <footer-primary />
</template>

<script>
import FooterPrimary from '@/components/FooterPrimary.vue';
import routerLogout from '@/functions/routerLogout';
export default {
    mounted(){
        routerLogout();
    },
    created(){
        document.title = this.$route.meta.title;
    },
    components: { FooterPrimary },
}
</script>

<style>

</style>