<template>
    <div class="fixed-action-btn">
        <a class="btn-floating btn-large teal darken-1 modal-trigger" href="#createPollModal">
            <i class="large material-icons">add</i>
        </a>
    </div>

    <div class="modal modal-fixed-footer" id="createPollModal">
        <div class="modal-content">
            <h6 class="center teal-text text-darken-1">Create a New Poll</h6>
            <br>
            <form>
                <div class="input-field">
                    <input id="poll_name" type="text" class="validate" name="name" autocomplete="off" required v-model="pname">
                    <label for="poll_name" class="teal-text text-darken-4">Name</label>
                </div>
                <div class="input-field">
                    <textarea id="poll_description" class="materialize-textarea spec-text" data-length="50" required v-model="pdesc"></textarea>
                    <label for="poll_description" class="teal-text text-darken-4">Description</label>
                </div>
                <label for="poll_view" class="teal-text text-darken-4">Access</label>
                <div class="row" id="poll_view">
                    <div class="col s6 m6 l6">
                        <label>
                            <input id="poll_view" type="radio" value="Private" required v-model="pview"/>
                            <span class="spec-text">Private</span>
                        </label> 
                    </div>
                    <div class="col s6 m6 l6">
                        <label>
                            <input id="poll_view" type="radio" value="Public" required v-model="pview"/>
                            <span class="spec-text">Public</span>
                        </label>
                    </div>
                </div>
                <section>
                    <div v-if="catLoader" class="center">
                        <loader-component />
                    </div>
                    <div v-else>
                        <div v-if="categories.length">
                            <label for="poll_category" class="teal-text text-darken-1">Category</label>
                            <select id="poll_category" class="browser-default" required v-model="pcat">
                                <option disabled selected>Choose</option>
                                <option v-for="category in categories" :key="category">{{ category.c }}</option>
                            </select>
                        </div>
                        <div v-else>
                            <p class="center red-text text-darken-4">{{ catMessage }}</p>
                        </div>
                    </div>
                </section>
                <br>
                <section>
                    <div v-if="typeLoader" class="center">
                        <loader-component />
                    </div>
                    <div v-else>
                        <div v-if="candidates.length">
                            <label for="poll_candidate" class="teal-text text-darken-1">Candidate</label>
                            <select id="poll_candidate" class="browser-default" required v-model="pcan">
                                <option disabled selected>Choose</option>
                                <option v-for="can in candidates" :key="can">{{ can.t }}</option>
                            </select>
                        </div>
                        <div v-else>
                            <p class="center red-text text-darken-4">{{ typeMessage }}</p>
                        </div>
                    </div>
                </section>
                <br>
                <div>
                    <div class="center" v-if="progress">
                        <loader-component />
                    </div>
                    <div v-else>
                        <button class="btn teal darken-4 white-text rounded full" @click.prevent="bouncer">Create a Poll</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <a class="modal-close waves-effect waves-green btn-flat red-text text-darken-4">Close</a>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import M from 'materialize-css'
import LoaderComponent from './LoaderComponent.vue';

export default {
    mounted(){
        M.AutoInit();
        this.loadCategories();
        this.loadTypes();
    },
    data(){
        return {
            progress: false,
            pname: null,
            pdesc: null,
            pview: null,
            pcat: null,
            pcan: null,
            authToken: localStorage.getItem('xWtyHBansFSGsOAMsdOAfQmcnKAs'),
            catLoader: true,
            catMessage: null,
            categories: [],
            typeLoader: true,
            typeMessage: null,
            candidates: [],
        }
    },
    methods: {
        bouncer(){
            if (this.pname === "" || this.pname === null || this.pname === 0) {
                alert('Empty Field - Poll Name');
            }else if (this.pdesc === "" || this.pdesc === null || this.pdesc === 0) {
                alert('Empty Field - Poll Description');
            }else if (this.pview === "" || this.pview === null || this.pview === 0) {
                alert('Empty Field - Poll Access');
            }else if (this.pcat === "" || this.pcat === null || this.pcat === 0) {
                alert('Empty Field - Poll Category');
            }else if (this.pcan === "" || this.pcan === null || this.pcan === 0) {
                alert('Empty Field - Poll Candidature');
            } else {
                this.progress = true;
                this.createPoll();
            }
        },
        async createPoll(){
            let data = new FormData();
            data.append('pk', this.authToken);
            data.append('pp', this.pname);
            data.append('pd', this.pdesc);
            data.append('pv', this.pview);
            data.append('pt', this.pcat);
            data.append('pn', this.pcan);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/np', data);
                let returnCondition = response.data.error
                let returnMessage = response.data.message

                if (returnCondition === false) {
                    this.progress = false
                    M.toast({html: returnMessage});
                    this.closeModal();
                    this.reloadMain();
                } else {
                    this.progress = false
                    M.toast({html: returnMessage});
                }
            } catch (error) {
                this.progress = false
                M.toast({html: 'Network Error. Try Again'});
                //console.log(error);
            }
        },
        closeModal(){
            var elem = document.getElementById("createPollModal");
            var instance = M.Modal.getInstance(elem);
            instance.close();
        },
        reloadMain(){
            this.$parent.reloadPolls();
        },
        async loadCategories(){
            let data = new FormData();
            data.append('npk', this.authToken)

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/npct', data)
                let returnCondition = response.data.error
                let returnMessage = response.data.message

                if (returnCondition === false) {
                    this.catLoader = false
                    this.catMessage = null
                    this.categories = response.data.thread
                } else {
                    this.catLoader = false
                    this.catMessage = returnMessage
                    this.categories = []
                }
            } catch (error) {
                this.catLoader = false
                this.catMessage = 'Network Error. Try Again'
                this.categories = []
                //console.log(error);
            }
        },
        async loadTypes(){
            let data = new FormData();
            data.append('npk', this.authToken)

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/npty', data)
                let returnCondition = response.data.error
                let returnMessage = response.data.message

                if (returnCondition === false) {
                    this.typeLoader = false
                    this.typeMessage = null
                    this.candidates = response.data.thread
                } else {
                    this.typeLoader = false
                    this.typeMessage = returnMessage
                    this.candidates = []
                }
            } catch (error) {
                this.typeLoader = false
                this.typeMessage = 'Network Error. Try Again'
                this.candidates = []
                //console.log(error);
            }
        }
    },
    components: { LoaderComponent, }
}
</script>

<style scoped>
    .rounded{
        border-radius: 7px;
    }

    .full{
        width: 100%
    }

    .lg {
        width: 75% !important;
    }
</style>