<template>
    <navbar-secondary />
    <sidebar />
    <div class="container-fluid">
        <div v-if="pollLoader" class="center">
            <loader-component />
        </div>
        <div v-else>
            <div v-if="polls.length">
                <div class="row">
                    <div class="col s12 m12 l12">
                        <search-polls-component />
                    </div>
                    <div class="col s12 m4 l3" v-for="poll in polls" :key="poll">
                        <div class="card-panel hoverable rounded color-me-impressed">
                            <router-link :to="{name: 'poll', params: {pageValue: poll.tz}}">
                                <div class="card-content">
                                    <h6 class="teal-text text-darken-1 truncate">{{ poll.tp }}</h6>
                                    <span class="truncate spec-text">{{ poll.td }}</span>
                                </div>
                            </router-link>
                            
                        </div>
                    </div>
                    <div class="col s12 m12 l12">
                        <section>
                            <ul class="pagination center">
                                <li v-for="index in pollTotal" :key="index" style="margin-right: 10px; margin-left: 10px;">
                                    <div v-if="index === pageCurrent">
                                        <span class="teal-text text-darken-1" style="text-decoration: none; cursor: pointer; text-decoration: line-through;" @click.prevent="loadPolls(index)">{{ index }}</span>
                                    </div>
                                    <div v-else>
                                        <span style="text-decoration: none; cursor: pointer;" @click.prevent="loadPolls(index)">{{ index }}</span>
                                    </div>
                                </li>
                            </ul>
                        </section>
                    </div>
                </div>

                <poll-registration/>
            </div>
            <div v-else>
                <p class="center red-text text-darken-4">{{ pollNegativeMessage }}</p>
            </div>
        </div>
    </div>
    <footer-primary />
</template>

<script>
import axios from 'axios'
import FooterPrimary from '@/components/FooterPrimary.vue'
import NavbarSecondary from '@/components/NavbarSecondary.vue'
import Sidebar from '@/components/Sidebar.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'
import SearchPollsComponent from '@/components/SearchPollsComponent.vue'
import PollRegistration from '@/components/PollRegistration.vue'
export default {
    props: {
        pageValue: {
            default: 1,
            type: String
        }
    },
    data(){
        return {
            authToken: localStorage.getItem('xWtyHBansFSGsOAMsdOAfQmcnKAs'),
            pollLoader: true,
            pollNegativeMessage: null,
            polls: [],
            pollTotal: null,
            pageCurrent: null,
            
        }
    },
    methods: {
        reloadPolls(){
            this.loadPolls(this.pageValue);
        },
        async loadPolls(pageValue){
            let data = new FormData()
            data.append('pk', this.authToken)
            data.append('pv', pageValue)

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/polls', data)
                let returnCondition = response.data.error 
                let returnMessage = response.data.message 

                if (returnCondition === false) {
                    this.pollLoader = false
                    this.pollNegativeMessage = null
                    this.polls = response.data.thread
                    this.pollTotal = response.data.pn
                    this.pageCurrent = response.data.pc
                } else {
                    this.pollLoader = false
                    this.polls = []
                    this.pollNegativeMessage = returnMessage
                }
            } catch (error) {
                this.pollLoader = false
                this.polls = []
                this.pollNegativeMessage = 'Network Error. Try Again'
                //console.log(error);
            }
        },
    },
    mounted(){
        this.loadPolls(this.pageValue);
    },
    created(){
        document.title = this.$route.meta.title;
        this.loadPolls(this.pageValue);
    },
    components: { FooterPrimary, NavbarSecondary, Sidebar, LoaderComponent, SearchPollsComponent, PollRegistration },
}
</script>

<style scoped>
    .color-me-impressed{
        border-left-style: solid;
        border-left-width: 5px;
        border-left-color: #004d40;
    }

    .rounded{
        border-radius: 7px;
    }
</style>