<template>
    <div class="row">
        <div class="col s12 m2 l2"></div>
        <div class="col s12 m8 l8">
            <div class="card-panel rounded z-depth-2">
                <p class="center-align"><img src="@/assets/rs_forgot_password.svg" style="width: 152px; height: 152px;"/></p>
                <form action="">
                    <div class="input-field">
                        <input id="rec_uname" type="text" class="validate" required autocomplete="off" v-model="rec_uname">
                        <label for="rec_uname" class="teal-text text-darken-1">Username</label>
                    </div>
                    <div>
                        <div v-if="rec_loader" class="center">
                            <loader-component />
                        </div>
                        <div v-else>
                            <button class="btn teal darken-4 white-text full rounded" @click.prevent="bouncerCheck">Recover Password</button>
                        </div>
                    </div>
                </form>
                <p class="center">
                    <router-link :to="{name: 'home'}" class="teal-text text-darken-1">Back to Sign In</router-link>
                </p>
            </div>
        </div>
        <div class="col s12 m2 l2"></div>
    </div>
</template>

<script>
import LoaderComponent from './LoaderComponent.vue';
import M from 'materialize-css'
import axios from 'axios';
import router from '@/router'

export default {
    props: {
        page_key: {
            type: String,
            required: true,
        }
    },
    data(){
        return{
            rec_uname: null,
            rec_loader: false,
        }
    },
    methods: {
        bouncerCheck(){
            if (this.rec_uname === null || this.rec_uname === "" || this.rec_uname === 0) {
                alert('Empty Field - Username');
            } else {
                this.rec_loader = true;
                this.doPasswordRecovery();
            }
        },
        async doPasswordRecovery(){
            let data = new FormData();
            data.append('fpu', this.rec_uname);
            data.append('fpz', this.page_key);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/pf', data);
                let returnCondition = response.data.error 
                let returnMessage = response.data.message 

                if (returnCondition === false) {
                    this.rec_loader = false
                    M.toast({html: returnMessage });
                    this.redirectHome();
                } else {
                    this.rec_loader = false
                    M.toast({html: returnMessage });
                }
            } catch (error) {
                this.rec_loader = false
                M.toast({html: 'Network Error. Try Again'});
                //console.log(error);
            }
        },
        redirectHome(){
            router.push({name: 'home'});
        },
    },
    components: { LoaderComponent, },
}
</script>

<style scoped>
    .rounded{
        border-radius: 14px;
    }

    .full{
        width: 100%
    }
</style>