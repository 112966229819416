<template>
  <navbar-primary />
  <div class="container">
    <div v-if="bouncerLoader" class="center">
      <loader-component />
    </div>
    <div v-else>
      <div v-if="bouncerStatus === 1">
        <sign-in-component :page_key="bouncerToken"/>
      </div>
      <div v-else>
        <p class="center red-text text-darken-4">{{ bouncerMessage }}</p>
      </div>
    </div>
  </div>
  <footer-primary />
</template>

<script>
import SignInComponent from '@/components/SignInComponent.vue'
import FooterPrimary from '@/components/FooterPrimary.vue';
import NavbarPrimary from '@/components/NavbarPrimary.vue';
import LoaderComponent from '@/components/LoaderComponent.vue';
import axios from 'axios';

export default {
  data(){
    return {
      bouncerLoader: true,
      bouncerMessage: null,
      bouncerStatus: 0,
      pageCodeWord: 'nIklaUs',
      bouncerToken: null,
    }
  },
  methods: {
    async Bouncerfy(){
      let data = new FormData();
      data.append('az', this.pageCodeWord);

      try {
        const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/a1', data)
        let returnCondition = response.data.error 
        let returnMessage = response.data.message 

        if (returnCondition === false) {
          this.bouncerLoader = false
          this.bouncerStatus = 1
          this.bouncerToken = response.data.tzg
        } else {
          this.bouncerLoader = false
          this.bouncerMessage = returnMessage
        }
      } catch (error) {
        this.bouncerLoader = false
        this.bouncerMessage = 'Network Error. Try Again'
        //console.log(error);
      }
    },
  },
  mounted(){
    this.Bouncerfy();
  },
  created(){
    document.title = this.$route.meta.title;
  },
  components: { SignInComponent, FooterPrimary, NavbarPrimary, LoaderComponent, },

}
</script>

<style>

</style>