<template>
    <div class="fixed-action-btn">
        <a class="btn-floating btn-large red darken-1 modal-trigger" href="#poll-deactivate">
            <i class="large material-icons">lock</i>
        </a>
    </div>

    <div id="poll-deactivate" class="modal">
        <div class="modal-content">
            <h6 class="center red-text text-darken-1">Deactivate Poll</h6>
            <br>
            <form action="">
                <div v-if="progress" class="center">
                    <loader-component />
                </div>
                <div v-else>
                    <button class="btn red darken-1 white-text rounded full" @click.prevent="bouncer">Deactivate Poll</button>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <a class="modal-close waves-effect waves-green btn-flat red-text text-darken-4">Close</a>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import M from 'materialize-css';
import LoaderComponent from './LoaderComponent.vue';
export default {
    props: {
        poll_name: {
            type: String,
            required: true,
        }
    },
    data(){
        return{
            authToken: localStorage.getItem('xWtyHBansFSGsOAMsdOAfQmcnKAs'),
            progress: false,
        }
    },
    methods: {
        bouncer(){
            if (this.poll_name === "" || this.poll_name === 0 || this.poll_name === null) {
                alert('Err. Contact The System Administrator')
            }else{
                this.progress = true
                this.deactivatePoll();
            }
        },
        async deactivatePoll(){
            let data = new FormData();
            data.append('dpk', this.authToken);
            data.append('dpp', this.poll_name);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/pd', data)
                let returnCondition = response.data.error 
                let returnMessage = response.data.message 

                if (returnCondition === false) {
                    this.progress = false
                    M.toast({html: returnMessage});
                    this.$parent.poll_details();
                    this.closeModal();
                } else {
                    this.progress = false
                    M.toast({html: returnMessage});
                }
            } catch (error) {
                this.progress = false
                M.toast({html: 'Network Error. Try Again'});
                console.log(error);
            }
        },
        closeModal(){
            var elem = document.getElementById("poll-deactivate");
            var instance = M.Modal.getInstance(elem);
            instance.close();
        },

    },
    mounted(){
        M.AutoInit();
    },
    components: { LoaderComponent, }
}   
</script>

<style scoped>
    .rounded{
        border-radius: 14px;
    }

    .full{
        width: 100%;
    }
</style>