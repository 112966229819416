<template>
    <navbar-primary />
    <div class="container">
        <div v-if="validatorLoader" class="center">
            <loader-component />
        </div>
        <div v-else>
            <div v-if="validatorStatus === 1">
                <setup-component :targetToken="setupToken"/>
            </div>
            <div>
                <p class="center red-text text-darken-4">{{ validatorMessage }}</p>
            </div>
        </div>
    </div>
    <footer-primary />
</template>

<script>
import NavbarPrimary from '@/components/NavbarPrimary.vue'
import FooterPrimary from '@/components/FooterPrimary.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'
import axios from 'axios'
import SetupComponent from '@/components/SetupComponent.vue'
export default {
    props: {
        setupToken: {
            required: true,
            type: String,
        }
    },
    data(){
        return{
            validatorLoader: true,
            validatorMessage: null,
            validatorStatus: 0
        }
    },
    methods: {
        async validateToken(){
            let data = new FormData();
            data.append('sts', this.setupToken);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/stv', data)
                let returnCondition = response.data.error
                let returnMessage = response.data.message 

                if (returnCondition === false) {
                    this.validatorLoader = false
                    this.validatorMessage = null
                    this.validatorStatus = 1
                } else {
                    this.validatorLoader = false
                    this.validatorMessage = returnMessage
                    this.validatorStatus = 0
                }
            } catch (error) {
                this.validatorLoader = false
                this.validatorMessage = 'Network Error. Try Again'
                this.validatorStatus = 0
                //console.log(error);
            }
        }
    },
    mounted(){
        this.validateToken();
    },
    created(){
        document.title = this.$route.meta.title;
    },
    components: { LoaderComponent, NavbarPrimary, FooterPrimary, SetupComponent,  },
}
</script>

<style scoped>

</style>