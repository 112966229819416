<template>
    <div class="row">
        <div class="col s12 m1 l2"></div>
        <div class="col s12 m10 l8">
            <div class="card-panel rounded">
                <div class="card-content">
                    <p class="center-align"><img src="@/assets/rs_sign_in.svg" style="width: 152px; height: 152px;"/></p>
                    <form>
                        <div class="input-field">
                            <input id="user_name" type="text" class="validate" required autocomplete="off" v-model="sin_uname">
                            <label for="user_name" class="teal-text text-darken-1">Username</label>
                        </div>
                        <div class="input-field">
                            <input id="user_pass" type="password" class="validate" required autocomplete="off" v-model="sin_pass">
                            <label for="user_pass" class="teal-text text-darken-1">Password</label>
                        </div>

                        <div @click.prevent="togglePassword()" class="right" style="margin-bottom:0px; margin-top: -21px; cursor: pointer; text-decoration: none;">
                            <div v-if="passwordStatus" style="cursor: pointer;">
                                <p id="l_toggle">Hide Password</p>
                            </div>
                            <div v-else style="cursor: pointer;">
                                <p id="l_toggle">Show Password</p>
                            </div>
                        </div>
                        <br><br>
                        <div>
                            <div class="center" v-if="progress">
                                <loader-component />
                            </div>
                            <div v-else>
                                <button class="btn teal darken-4 white-text rounded full" @click.prevent="bouncer">Sign In</button>
                            </div>
                        </div>
                        
                        <p class="center" style="margin-bottom: -14px;"><router-link class="teal-text text-darken-1" :to="{name: 'pr'}">Forgot Password</router-link></p>
                        <br>
                    </form>
                </div>
            </div>
        </div>
        <div class="col s12 m1 l2"></div>
    </div>
</template>

<script>
import LoaderComponent from './LoaderComponent.vue'
import M from 'materialize-css'
import axios from 'axios'
import router from '@/router'

export default {
    props: {
        page_key: {
            type: String,
            required: true,
        }
    },
    data(){
        return{
            localUsr: localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs"),
            sin_uname: null,
            sin_pass: null,
            progress: false,
            passwordStatus: false
        }
    },
    beforeMount(){
        this.checkForUser();
    },
    methods: {
        checkForUser(){
            if (this.localUsr !== "" || this.localUsr !== 0 || this.localUsr !== null) {
                this.dashRedirect();
            }
        },
        dashRedirect(){
            router.push({name: 'dash', params: {pageValue: 1}});
        },
        togglePassword(){
            var x = document.getElementById("user_pass");
            var text = document.getElementById("l_toggle")
            if (x.type === "password") {
                this.passwordStatus = true;
                text.innerHTML = "Hide Password";
                x.type = "text";
            } else {
                this.passwordStatus = false;
                text.innerHTML = "Show Password";
                x.type = "password";
            }
        },
        bouncer(){
            if (this.sin_uname === "" || this.sin_uname === null || this.sin_uname === 0) {
                alert('Empty Field - Username');
            }else if (this.sin_pass === "" || this.sin_pass === null || this.sin_pass === 0) {
                alert('Empty Field - Password');
            } else if (this.page_key === "" || this.page_key === null || this.page_key === 0) {
                alert('Err 000. Contact The System Administrator');
            } else{
                this.progress = true
                this.zhuli();
            }
        },
        async zhuli(){
            let data = new FormData();
            data.append('au', this.sin_uname);
            data.append('ap', this.sin_pass);
            data.append('az', this.page_key);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/sin', data)
                let returnCondition = response.data.error
                let returnMessage = response.data.message
                let returnID = response.data.tsi

                if (returnCondition === false) {
                    this.progress = false
                    localStorage.setItem("xWtyHBansFSGsOAMsdOAfQmcnKAs", returnID);
                    sessionStorage.setItem("xWtyHBansFSGsOAMsdOAfQmcnKAs", returnID);
                    this.dashRedirect();
                } else {
                    this.progress = false
                    M.toast({html: returnMessage}); 
                }
            } catch (error) {
                this.progress = false
                M.toast({html: 'Network Error. Try Again'});
                //console.log(error);
            }
        },
    },
    components: { LoaderComponent },
}
</script>

<style scoped>
    .rounded{
        border-radius: 14px;
    }

    .full{
        width: 100%
    }
</style>