<template>
    <div class="progress">
        <div class="indeterminate teal darken-4"></div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>