<template>
    <navbar-secondary />
    <sidebar />
    <div class="container">
        <div v-if="categoryLoader" class="center">
            <loader-component />
        </div>
        <div v-else>
            <div v-if="categories.length">
                <blockquote>{{ pollName }}'s Categories</blockquote>
                <table class="striped highlight">
                    <thead class="teal-text text-darken-">
                        <tr>
                            <td>Category</td>
                            <td>Manage</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="category in categories" :key="category">
                            <td>{{ category.cn }}</td>
                            <td>
                                <router-link :to="{name: 'catm', params: {pageValue: pageValue, categoryID: category.cx}}">
                                    <i class="material-icons teal-text text-darken-4">arrow_forward</i>
                                </router-link>
                            </td>    
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else>
                <p class="center red-text text-darken-4">{{ categoryMessage }}</p>
            </div>          
        </div>
        
        <section>
            <div v-if="allowCreation">
                <category-registration :poll_name="pageValue"/>
            </div>
        </section>
    </div>
    <footer-primary />
</template>

<script>
import NavbarSecondary from '@/components/NavbarSecondary.vue'
import Sidebar from '@/components/Sidebar.vue'
import FooterPrimary from '@/components/FooterPrimary.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'
import CategoryRegistration from '@/components/CategoryRegistration.vue'
import axios from 'axios'
import M from 'materialize-css'

export default {
    props: ['pageValue'],
    mounted(){
        this.get_categories();
    },
    data(){
        return{
            authToken: localStorage.getItem('xWtyHBansFSGsOAMsdOAfQmcnKAs'),
            categoryLoader: true,
            categoryMessage: null,
            categories: [],
            pollName: null,
            cname: null,
            modalLoader: false,
            allowCreation: false,
        }
    },
    methods: {
        async get_categories(){
            let data = new FormData();
            data.append('cnk', this.authToken)
            data.append('cnt', this.pageValue)

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/cat', data)
                let returnCondition = response.data.error
                let returnMessage = response.data.message

                if (returnCondition === false) {
                    this.categoryLoader = false
                    this.categoryMessage = null
                    this.categories = response.data.thread
                    this.pollName = response.data.p
                    this.allowCreation = response.data.a
                } else {
                    this.categoryLoader = false
                    this.categoryMessage = returnMessage
                    this.categories = []
                    this.allowCreation = response.data.a
                }
            } catch (error) {
                this.categoryLoader = false
                this.categoryMessage = 'Network Error. Try Again'
                this.categories = []
                //console.log(error);
            }
        },
    },
    created(){
        document.title = this.$route.meta.title;
    },
    components: { NavbarSecondary, Sidebar, FooterPrimary, LoaderComponent, CategoryRegistration },
}
</script>

<style scoped>
    .rounded{
        border-radius: 7px;
    }
    .full{
        width: 100%;
    }
</style>