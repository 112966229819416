<template>
    <navbar-secondary />
    <sidebar />
    <div class="container">
        <p class="center">Account</p>
    </div>
    <footer-primary />
</template>

<script>
import FooterPrimary from "@/components/FooterPrimary.vue"
import Sidebar from "@/components/Sidebar.vue"
import NavbarSecondary from '@/components/NavbarSecondary.vue'

export default {
    components: { FooterPrimary, Sidebar, NavbarSecondary },
    created(){
        document.title = this.$route.meta.title;
    },
}
</script>

<style>

</style>