<template>
    <div class="card rounded">
        <div class="card-content center">
            <router-link :to="{name: 'asm', params: {pageValue: poll}}">
                <span class="spec-text">Aspirants</span>
                <div v-if="aspirantLoader" class="center">
                    <loader-secondary />
                </div>
                <div v-else>
                    <div v-if="aspirantCount === null">
                        <p class="center red-text text-darken-4"><small>{{ aspirantMessage }}</small></p>
                    </div>
                    <div v-else>
                        <p class="flow-text">{{ aspirantCount }}</p>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import LoaderSecondary from './LoaderSecondary.vue'
export default {
    props: ['poll'],
    data(){
        return{
            authToken: localStorage.getItem('xWtyHBansFSGsOAMsdOAfQmcnKAs'),
            aspirantCount: null,
            aspirantLoader: true,
            aspirantMessage: null
        }
    },
    methods: {
        async secondary_aspirant_count(){
            let data = new FormData()
            data.append('sack', this.authToken)
            data.append('sact', this.poll)

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/sac', data)
                let returnCondition = response.data.error
                let returnMessage = response.data.message 

                if (returnCondition === false) {
                    this.aspirantLoader = false
                    this.aspirantCount = response.data.mk7
                    this.aspirantMessage = null
                } else {
                    this.aspirantLoader = false
                    this.aspirantCount = null
                    this.aspirantMessage = returnMessage
                }
            } catch (error) {
                this.aspirantLoader = false
                this.aspirantCount = null
                this.aspirantMessage = 'Network Error. Try Again'
                //console.log(error);
            }
        },
    },
    mounted(){
        this.secondary_aspirant_count();
    },
    components: { LoaderSecondary },
}
</script>

<style>

</style>