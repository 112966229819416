<template>
    <navbar-primary />
    <div class="container">
        <div v-if="validator_loader" class="center">
            <loader-component />
        </div>
        <div v-else>
            <div v-if="validator_status === 1">
                <recovery-center-component :validToken="recoveryToken"/>
            </div>
            <div v-else>
                <p class="center red-text text-darken-4">{{ validator_message }}</p>
            </div>
        </div>
    </div>
    <footer-primary />
</template>

<script>
import NavbarPrimary from '@/components/NavbarPrimary.vue'
import FooterPrimary from '@/components/FooterPrimary.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'
import axios from 'axios'
import M from 'materialize-css'
import RecoveryCenterComponent from '@/components/RecoveryCenterComponent.vue'

export default {
    props: {
        recoveryToken: {
            required: true,
            type: String,
        }
    },
    data(){
        return{
            validator_loader: true,
            validator_message: null,
            validator_status: 0
        }
    },
    methods: {
        async validator(){
            let data = new FormData();
            data.append('rcx', this.recoveryToken);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/rcv', data)
                let returnCondition = response.data.error 
                let returnMessage = response.data.message 

                if (returnCondition === false) {
                    this.validator_loader = false
                    this.validator_message = null
                    this.validator_status = 1
                } else {
                    this.validator_loader = false
                    this.validator_message = returnMessage
                    this.validator_status = 0
                }
            } catch (error) {
                this.validator_loader = false
                this.validator_message = 'Network Error. Try Again'
                this.validator_status = 0
                //console.log(error);
            }
        },
    },  
    mounted(){
        this.validator();
    },
    created(){
        document.title = this.$route.meta.title;
    },
    components: { NavbarPrimary, FooterPrimary, LoaderComponent, RecoveryCenterComponent },
}
</script>

<style scoped>
    .rounded{
        border-radius: 14px;
    }

    .full{
        width: 100%
    }
</style>