import { createRouter, createWebHistory } from 'vue-router'
import routerLogout from '@/functions/routerLogout'
import HomeView from '../views/HomeView.vue'
import PasswordRecovery from '../views/PasswordRecoveryView.vue'
import Dashboard from '../views/DashboardView.vue'
import Account from '../views/AccountView.vue'
import Poll from '../views/PollView.vue'
import Error404 from '../views/Error404View.vue'
import Category from '../views/CategoryView.vue'
import CategoryManager from '../views/CategoryManagerView.vue'
import AspirantsManager from '../views/AspirantsManagerView.vue'
import ResultsPrimary from '../views/ResultsPrimaryView.vue'
import ResultsSecondary from '../views/ResultsSecondaryView.vue'
import CategoryResults from '../views/CategoryResultsView.vue'
import VoterManager from '../views/VoterManagerView.vue'
import DelegateView from '../views/DelegateView.vue'
import BasicView from '../views/BasicView.vue'
import RecoveryCenter from '../views/RecoveryCenterView.vue'
import Setup from '../views/SetupView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'Sign In - iVoting' },
  },
  {
    path: '/password-recovery',
    name: 'pr',
    component: PasswordRecovery,
    meta: { title: 'Password Recovery - iVoting' },
  },
  {
    path: '/dashboard/:pageValue',
    name: 'dash',
    props: true,
    component: Dashboard,
    meta: { title: 'Dashboard - iVoting' },
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/account',
    name: 'acct',
    component: Account,
    meta: { title: 'Account - iVoting' },
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/poll/:pageValue',
    name: 'poll',
    props: true,
    component: Poll,
    meta: { title: 'Poll - iVoting' },
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/categories/:pageValue',
    name: 'pools',
    props: true,
    component: Category,
    meta: { title: 'Categories - iVoting' },
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/category-manager/:pageValue/:categoryID',
    name: 'catm',
    props: true,
    component: CategoryManager,
    meta: { title: 'Category Manager - iVoting' },
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/aspirant-manager/:pageValue',
    name: 'asm',
    props: true,
    component: AspirantsManager,
    meta: { title: 'Aspirant Manager - iVoting' },
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/results/:pageValue',
    name: 'results-primary',
    props: true,
    component: ResultsPrimary,
    meta: { title: 'Results - iVoting' },
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/result/:pageValue',
    name: 'results-sec',
    props: true,
    component: ResultsSecondary,
    meta: { title: 'Results - iVoting' },
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/category-results/:pageValue/:categoryID',
    name: 'cat-res',
    props: true,
    component: CategoryResults,
    meta: { title: 'Category Results - iVoting' },
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/voter-manager/:pageValue/:page',
    name: 'voterm',
    props: true,
    component: VoterManager,
    meta: { title: 'Voter Manager - iVoting' },
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/delegate/:pageValue/:selectedVoter',
    name: 'delegate',
    props: true,
    component: DelegateView,
    meta: { title: 'Delegate - iVoting' },
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/basic/:pageValue/:selectedVoter',
    name: 'basic',
    props: true,
    component: BasicView,
    meta: { title: 'Basic - iVoting' },
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGsOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/recovery/:recoveryToken',
    name: 'recovery',
    props: true,
    component: RecoveryCenter,
    meta: { title: 'Recovery Center - iVoting' }
  },
  {
    path: '/setup/:setupToken',
    name: 'setup',
    props: true,
    component: Setup,
    meta: { title: 'Account Setup - iVoting' }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: Error404,
    meta: { title: 'Error 404 - iVoting' }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
