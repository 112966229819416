<template>
    <nav class="transparent z-depth-0" style="margin-bottom: 14px;">
        <div class="nav-wrapper" style="margin-right: 5px; margin-left: 5px;">
            <router-link :to="{name: 'home'}" class="brand-logo red-text text-darken-4 center">
                <img src="../assets/logo.png" style="width: 49px; height: 49px; margin-left: 5px; margin-top: 10px;"/>
            </router-link>
        </div>
    </nav>
</template>

<script>
export default {

}
</script>

<style>

</style>