<template>
    <footer class="transparent">
        <p class="center-align">Copyright &copy; 2019 - {{ currentYear }}. <span class="teal-text text-darken-1">{{ targetProduct }}</span>. A <span class="teal-text text-darken-1">{{ targetCompany }}</span> Product.</p>
    </footer>
</template>

<script>
export default {
    data(){
        return{
            targetProduct: 'iVoting',
            targetCompany: 'Roman Solutions'
        }
    },
    methods:{
        dateTimeVals(){
            const today = new Date();
            this.currentYear = today.getFullYear();
        }
    },
    beforeMount(){
        this.dateTimeVals();
    },
}
</script>

<style>

</style>