<template>
    <navbar-secondary />
    <sidebar />
    <div class="container">
        <div v-if="voterLoader" class="center">
            <loader-component />
        </div>
        <div v-else>
            <div v-if="voters.length">
                <blockquote>{{ voterPoll }} / <span class="teal-text text-darken-1">{{ voterTotal }}</span> Voters</blockquote>
                <div v-if="isPool === true">
                    <table class="striped highlight">
                        <thead class="teal-text text-darken-1">
                            <tr>
                                <td>Voter</td>
                                <td>Date</td>
                                <td>Delegate</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="voter in voters" :key="voter">
                                <td>{{ voter.v }}</td>
                                <td>{{ voter.t }}</td>
                                <div v-if="voter.d === 1" class="valign-wrapper">
                                    <td>
                                        <router-link :to="{name: 'basic', params: {pageValue: pageValue, selectedVoter: voter.v}}">
                                            <span class="teal-text text-darken-1">Yes</span>
                                        </router-link>
                                    </td>
                                </div>
                                <div v-else class="valign-wrapper">
                                    <td>
                                        <router-link :to="{name: 'delegate', params: {pageValue: pageValue, selectedVoter: voter.v}}">
                                            <span class="red-text text-darken-4">No</span>
                                        </router-link>
                                    </td>
                                </div>
                                
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else>
                    <table class="striped highlight">
                        <thead class="teal-text text-darken-1">
                            <tr>
                                <td>Voter</td>
                                <td>Date</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="voter in voters" :key="voter">
                                <td>{{ voter.v }}</td>
                                <td>{{ voter.t }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <section>
                    <div class="row">
                        <div class="col s12 m12 l12">
                            <ul class="pagination center">
                                <li v-for="index in pageNumber" :key="index" style="margin-right: 10px; margin-left: 10px;">
                                    <div v-if="index === currentPage">
                                        <span class="teal-text text-darken-1" style="text-decoration: none; cursor: pointer; text-decoration: line-through;" @click.prevent="comicBookStore(index)">{{ index }}</span>
                                    </div>
                                    <div v-else>
                                        <span style="text-decoration: none; cursor: pointer;" @click.prevent="comicBookStore(index)">{{ index }}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
            <div v-else>
                <p class="center red-text text-darken-">{{ voterMessage }}</p>
            </div>

            <div v-if="allowRegistration">
                <voter-registration :poll="pageValue" :category="pollCategory"/>
            </div>
            
            <div>
                <section>
                    <div class="modal" id="revert-basic">
                        <div class="modal-content">
                            <h6>to basic</h6>
                        </div>
                    </div>
                </section>
            </div>

        </div>
    </div>
    <footer-primary />
</template>

<script>
import axios from 'axios';
import NavbarSecondary from '@/components/NavbarSecondary.vue'
import Sidebar from '@/components/Sidebar.vue';
import FooterPrimary from '@/components/FooterPrimary.vue';
import LoaderComponent from '@/components/LoaderComponent.vue';
import VoterRegistration from '@/components/VoterRegistration.vue';

export default {
    props: {
        pageValue:{
            type: String,
            required: true
        },
        page: {
            type: String,
            required: true,
        }
    },
    data(){
        return{
            authToken: localStorage.getItem('xWtyHBansFSGsOAMsdOAfQmcnKAs'),
            voterLoader: true,
            voterMessage: null,
            voters: [],
            voterPoll: null,
            voterTotal: null,
            isPool: false,
            pageNumber: null,
            currentPage: null,
            allowRegistration: false,
            pollCategory: false,
        }
    },
    methods: {
        reloadRegister(){
            this.comicBookStore(this.page);
        },
        async comicBookStore(page){
            let data = new FormData();
            data.append('vrk', this.authToken);
            data.append('vrt', this.pageValue);
            data.append('vrx', page);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AdjnINBsmPANd/vr', data)
                let returnCondition = response.data.error
                let returnMessage = response.data.message

                if (returnCondition === false) {
                    this.voterLoader = false
                    this.voterMessage = null
                    this.voters = response.data.t
                    this.voterPoll = response.data.p
                    this.isPool = response.data.z
                    this.voterTotal = response.data.vt
                    this.pageNumber = response.data.pn 
                    this.currentPage = response.data.pc
                    this.allowRegistration = response.data.a
                    this.pollCategory = response.data.k
                } else {
                    this.voterLoader = false
                    this.voterMessage = returnMessage
                    this.voters = []
                    this.allowRegistration = response.data.a
                    this.pollCategory = response.data.k
                }
            } catch (error) {
                this.voterLoader = false
                this.voterMessage = 'Network Error. Try Again'
                this.voters = []
                //console.log(error);
            }
        }
    },
    mounted(){
        this.comicBookStore(this.page);
    },
    created(){
        document.title = this.$route.meta.title;
    },
    components: { NavbarSecondary, Sidebar, FooterPrimary, LoaderComponent, VoterRegistration, },
}
</script>

<style scoped>
    .rounded-full{
        border-radius: 21px;
    }
</style>